import React ,{ useState,useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import config from './Common/Config';

export default function Contactus()
{
  const [showAlert, setShowAlert] = useState(true);

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');  

  const handleClose = () => 
    {
    setShowAlert(false);

    setErrorMessage('');

    setSuccessMessage('');

    setTimeout(() => 
      {
        setShowAlert(true);
      }, 1000);
    };
  
    const mobileNumberRef = useRef(null);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobileno: '',
        message: '',
      });

    const handleChange = (e) => 
      {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

      const handleSubmit = async (e) => 
      {
        e.preventDefault();

        const mobileNo = formData.mobileno;
        if (!isValidMobileNumber(mobileNo)) 
        {
          setErrorMessage('Mobile number must be a 10-digit number.');
          mobileNumberRef.current.focus();
          return;
        }

        try 
        {
          const apiUrl = config.apiBaseUrl + 'Contact_Enquiry.php';

          const configparameter = 
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          };

          const response = await axios.post(apiUrl, formData, configparameter);
              
        //   console.log('API response:', response.data);

          const Status=response.data.Status;
          
          console.log(Status);

          
          if(Status===1)
          {
            setFormData({
                name: '',
                email: '',
                mobileno: '',
                message: '',
              });
    
              setSuccessMessage("success");

              setTimeout(() => {
                setSuccessMessage('');
              }, 5000);
          }
          else if(Status===2)
          {
            setErrorMessage("Mobile number is already use for enquiry.");
          }
          else if(Status===0)
          {
            setErrorMessage("Message is not submited try again.");

            setTimeout(() => {
                setErrorMessage('');
              }, 5000);
          }

          // Optionally, you can reset the form after successful submission
        } 
        catch (error) 
        {
          setErrorMessage("error");

          console.error('API error:', error);
        }
      };

      // Function to validate mobile number as 10 digits
        function isValidMobileNumber(mobileNo) 
        {
            // Remove non-digit characters
            const cleanedNumber = mobileNo.replace(/\D/g, '');
            return cleanedNumber.length === 10;
        }

    return(
        <>
         <div className="container-fuild mh-100vh bgblack">
          <div className="container pt-110">
            <div className="row">
              <div className="col-md-12">
               <h1 className="colorfff text-center">Contact Us</h1>

              <div className="text-center" style={{  display: 'flex',justifyContent:'center' }}>
                <div style={{  textAlign: 'center' }}>
        
                  <span style={{  color: 'white',marginRight:'10px' }}><FontAwesomeIcon icon={faEnvelope}   /> vivekjadhav.regular@gmail.com</span>

                  <span  style={{  color: 'white' }} ><FontAwesomeIcon icon={faPhone}  /> (+91) 9923550405</span>
                  
                  </div>
              </div>
              <br/>

              <div className="container">
              <form onSubmit={handleSubmit} id="demo-form2" data-parsley-validate name="form1" className="form-horizontal form-label-left" method="POST" enctype="multipart/form-data">
                      
                      <div className="row">
                      
                          <div className="col-md-12 justify-content-center d-flex">
                          <div className="col-md-6">
                          <div className="row">
                          <p className="colorfff">For any queries: </p>
                          <div className="col-sm-6 form-group">
                              <label>Name <span className="required">*</span></label>
                              <input type="text" name="name"  placeholder="Enter  Name Here.." className="form-control" required="required" value={formData.name} onChange={handleChange}  />
                          </div>
                        
                          <div className="col-sm-6 form-group">
                            <label>Mobile No.<span className="required">*</span></label>
                              <input type="tel" name="mobileno" pattern="[6789][0-9]{9}" placeholder="Enter Age Here.." className="form-control" required="required" minlength="10" maxlength="10"  value={formData.mobileno} onChange={handleChange}  ref={mobileNumberRef} />
                            </div>
                            
                            <div className="col-sm-6 form-group">
                            <br/>
                            <label>Email<span className="required">*</span></label>
                              <input type="email" name="email"  placeholder="Enter Email Here.." className="form-control" required="required" value={formData.email} onChange={handleChange}   />
                            </div>
                            <div className="col-sm-12 form-group">
                            <br/>
                              <label>Message <span className="required">*</span></label>
                              {/* <input type="text" name="name"   placeholder="Enter  Name Here.." className="form-control" required="required"/> */}
                              <textarea name="message" className="form-control" value={formData.message} onChange={handleChange} defaultValue={""} required></textarea>
                          </div>
                        
                          <div class="form-group">
                          <br/>
                        
                              <div class="col-md-12 col-sm-12 col-xs-12 col-md-offset-3">
                                
                                  <button type="submit" className="btn btn-success" name="submit">Submit</button>
                    
                                  {errorMessage && showAlert && <div className="alert alert-danger fade in  m-t10">
                                                      <a href="#" className="close" data-dismiss="alert" aria-label="close" onClick={handleClose}>×</a>
                                                      {errorMessage}
                                                  </div>}
                                                  
                                  {successMessage && showAlert && <div className="alert alert-success fade in m-t10">
                                            <a href="#" className="close" data-dismiss="alert" aria-label="close" onClick={handleClose}>×</a>
                                            <strong>Thank you </strong> our team contact you shortly!
                                        </div>}
                              </div>
                            </div>
                            
                      </div>
                          </div>
                          
                          </div>
                          
                    
                      </div>
                  </form>
              </div>
              
              </div>
            </div>
          </div>
    
       </div>
        </>
    )
}