import React from "react";
import { Link } from 'react-router-dom';

export default function Home()
{
    return(
        <>
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" data-interval="false">
          <div class="carousel-indicators carousel-indicators-numbers">
            
            <div id="rightSLideBar" className="hidden-xs hidden-sm">
                <ul className="colorfff ultagliststyle" >
            
                  <li className="pb-10 fw-bold"> <span  className="numbers  active" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0">01</span></li>

                  <li className="pb-10 fw-bold"> <span  className="numbers" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" >02</span></li>
                
                  <li className="pb-10 fw-bold"> <span className="numbers" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" >03</span></li>
                
                  <li className="pb-10 fw-bold"> <span  className="numbers" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" >04</span></li>
                  
                  <li className="pb-10 fw-bold"> <span  className="numbers" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" >05</span></li>
                  
                  <li className="pb-10 fw-bold"> <span  className="numbers"  data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" >06</span></li>

                  <li className="pb-10 fw-bold"> <span  className="numbers"  data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" >07</span></li>

                  <li className="pb-10 fw-bold"> <span  className="numbers"  data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" >08</span></li>

                  <li className="pb-10 fw-bold"> <span  className="numbers"  data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8" >09</span></li>


                </ul> 
            </div>
            
          </div>
          <Link to="/contactus" className="shopnowbtn fixtab"  > CONTACT US <i className="fa fa-angle-right righticon" ></i></Link>
          
          <span  className="footerurlsect">Friends Badminton Pvt Ltd © 2024 All Rights Reserved </span>
        
          <div class="carousel-inner">
            <div class="carousel-item active">
            <section className="hero   fullheigh section1" >
            <div className="maintxt">
                  <img src="assets/img/flex/first.jpg" className="img-responsive desktopview " />
                  <img src="assets/img/flex/M2.jpg" className="img-responsive mobileview " />
                  
                    <div className="textsection">
                    <span className="bannertext">"Smash the Competitaion<br /> <span className="textunderline">with Our Shuttlecock..."</span></span><br /><br />
                    </div>
              </div>
          </section>
            </div>
            <div class="carousel-item">
            <section className="hero   fullheigh section2 "  >
              <div className="maintxt">
                  <img src="assets/img/flex/second.jpg" className="img-responsive desktopview imgcls" />
                  <img src="assets/img/flex/M1.jpg" className="img-responsive mobileview imgcls" />
              
                    <div className="textsection">
                          <span className="bannertext">"Smash the Competitaion<br /> <span className="textunderline">with Our Shuttlecock..."</span></span><br /><br />
                          
                    </div>
              </div>
            
            </section>
            </div>
            <div class="carousel-item">
            <section className="hero   fullheigh section3"  >
          <div className="maintxt">
                  <img src="assets/img/flex/third.jpg" className="img-responsive desktopview imgcls" />
                  <img src="assets/img/flex/M3.jpg" className="img-responsive mobileview imgcls" />
                
                    <div className="textsection">
                    <span className="bannertext">"Smash the Competitaion<br /> <span className="textunderline">with Our Shuttlecock..."</span></span><br /><br />
                    </div>
              </div>
          </section>
            </div>
            <div class="carousel-item">
            <section className="hero   fullheigh section4" id="img_default" >
            <div className="maintxt">
                  <img src="assets/img/flex/fourth.jpg" className="img-responsive desktopview" />
                  <img src="assets/img/flex/M4.jpg" className="img-responsive mobileview" />
              
                    <div className="textsection">
                    <span className="bannertext">"Smash the Competitaion<br /> <span className="textunderline">with Our Shuttlecock..."</span></span><br /><br />
                  </div>         
              </div>
          </section>
            </div>
            <div class="carousel-item">
            <section className="hero   fullheigh section5" id="img_default" >
          <div className="maintxt">
                  <img src="assets/img/flex/fifth.jpg" className="img-responsive desktopview" />
                  <img src="assets/img/flex/M5.jpg" className="img-responsive mobileview" />
            
            <div className="textsection">
            <span className="bannertext">"Smash the Competitaion<br /> <span className="textunderline">with Our Shuttlecock..."</span></span><br /><br /> 
            
              </div>
              </div>
          </section>
            </div>
            <div class="carousel-item">
            <section className="hero   fullheigh section6" id="img_default" >
          
              <div className="maintxt">
                    <img src="assets/img/flex/six.jpg" className="img-responsive desktopview" />
                    <img src="assets/img/flex/M6.jpg" className="img-responsive mobileview" />
              
              <div className="textsection">
              <span className="bannertext">"Smash the Competitaion<br /> <span className="textunderline">with Our Shuttlecock..."</span></span><br /><br /> 
              </div>
              </div>
              
            </section>
            </div>
            <div class="carousel-item">
            <section className="hero   fullheigh section7" id="img_default" >

              <div className="maintxt">
                  <img src="assets/img/flex/seven.jpg" className="img-responsive desktopview" />
                  <img src="assets/img/flex/M7.jpg" className="img-responsive mobileview" />
              
            <div className="textsection">
            <span className="bannertext">"Smash the Competitaion<br /> <span className="textunderline">with Our Shuttlecock..."</span></span><br /><br />
            </div>
              </div>

            </section>
            </div>
            <div class="carousel-item">
            <section className="hero   fullheigh section8" id="img_default" >

            <div className="maintxt">
                <img src="assets/img/flex/eight.jpg" className="img-responsive desktopview" />
                <img src="assets/img/flex/M8.jpg" className="img-responsive mobileview" />
          
          <div className="textsection">
          <span className="bannertext">"Smash the Competitaion<br /> <span className="textunderline">with Our Shuttlecock..."</span></span><br /><br />
          </div>
            </div>

            </section>
            </div>

            <div class="carousel-item">
            <section className="hero   fullheigh section9" id="img_default" >

              <div className="maintxt">
                  <img src="assets/img/flex/nine.jpg" className="img-responsive desktopview" />
                  <img src="assets/img/flex/M9.jpg" className="img-responsive mobileview" />
          
            <div className="textsection">
            <span className="bannertext">"Smash the Competitaion<br /> <span className="textunderline">with Our Shuttlecock..."</span></span><br /><br />
            </div>
            </div>

            </section>
            </div>

          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        </>
    )
}