import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
export default function Menubar()
{
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to toggle modal state
    const toggleModal = () => 
        {
            setIsModalOpen(!isModalOpen);
        };

    // Function to close modal
    const closeModal = () => 
    {
        setIsModalOpen(false);

        console.log(isModalOpen);
    };




    return(
        <>
            <header id="header" className="fixed-top header-transparent">
                <div className="container-fluid d-flex align-items-center justify-content-between">

                <h1  className="logo"><a href="/"><img src="assets/img/flex/Flex-White-logo.png" className="headerlogo" /></a></h1>
                
                <a href="index.html" className="logo"><img src="assets/img/logo.png" alt="" className="img-fluid" /></a>

                <nav id="navbar" className="navbar">
                    
                <img src="assets/img/flex/toggleicon.png" className="toggleimage" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{cursor: 'pointer'}} />
                </nav>

                </div>
            </header>

      <Outlet />

      {/* <!--model start here --> */}

      <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: isModalOpen ? 'block' : 'none', width: '100%' }}>

            <div className="modal-dialog  modal-sm">
                <div className="modal-content">
                <div className="modal-header" style={{borderBottom: 'none'}}>
                
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{fontSize: '20px'}}></button>
                </div>
                <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        
                            
                        <div className="col-md-12">
                            <div className="koh-tab-content">
                            <div className="koh-tab-content-body">
                                <div className="koh-faq">
                                <div className="koh-faq-question ">
                                <span className="koh-faq-question-span litagmainlable spanlable animate slide-left delay-1"><Link to="/" className="color000" onClick={closeModal} > Home </Link></span> <i className="fa fa-chevron-right animate slide-left delay-1" aria-hidden="true" style={{fontSize: '25px',marginLeft: '10px'}}></i>
                                </div>
                          
                                </div>
                             
                                <div className="koh-faq">
                                <div className="koh-faq-question litagmainlable">
                                <span className="koh-faq-question-span spanlable animate slide-left delay-14">    
                                  <Link to="/aboutus" className="color000" onClick={closeModal} >About us</Link>   </span><i className="fa fa-chevron-right animate slide-left delay-14" aria-hidden="true" style={{fontSize: '25px',marginLeft: '10px'}}></i>
                                </div>
                            
                                </div>
                             
                                <div className="koh-faq">
                                <div className="koh-faq-question litagmainlable">
                                <span className="koh-faq-question-span spanlable animate slide-left delay-14"> <Link to="/contactus" onClick={closeModal}  className="color000">Contact us </Link>  </span><i className="fa fa-chevron-right animate slide-left delay-14" aria-hidden="true" style={{fontSize: '25px',marginLeft: '10px'}}></i>
                                </div>
                                </div>
                              
                            
                            </div>
                            </div>
                        </div>
                    
                    </div> 
                </div> 
                </div>
               
                </div>
            </div>
            </div>
           

        </>
    )
}