import React from "react";

export default function Aboutus()
{
    return(
        <>
         <div className="container-fuild mh-100vh bgblack">
        <div className="container pt-110">
        <div className="row">
        <div className="col-md-12">
         <h1 className="colorfff text-center">About Us</h1>
         <br/>

         <div className="container">

          <div className="col-md-12 justify-content-center d-flex">

            <div className="col-md-10">
            <p className="about-text">Welcome to FRIENDS BADMINTON, where badminton passion meets organized excellence! We are dedicated to bringing the thrill of badminton tournaments to enthusiasts of all levels, from casual players to seasoned competitors.<br></br>
                Our mission is simple: to create unforgettable badminton experiences that inspire, challenge, and unite players from diverse backgrounds. With our expertise in event management and a deep understanding of the sport, we strive to deliver seamless tournaments that exceed expectations.<br></br>
                Whether you're a solo player seeking to test your skills, a team hungry for victory, or a spectator eager to witness the intensity of competitive badminton, you'll find your place in our events. We offer a variety of tournaments tailored to different age groups, skill levels, and playing formats, ensuring there's something for everyone.<br></br>
                At FRIENDS BADMINTON, we prioritize fairness, sportsmanship, and camaraderie. Our tournaments are conducted with utmost integrity, and our experienced team ensures smooth operations from registration to prize distribution.<br></br>
                Join us on the court and experience the excitement of badminton like never before. Stay updated with upcoming events, browse through past tournaments, and discover valuable resources to enhance your game on our website.<br></br>
                Let's smash, rally, and soar together – because at FRIENDS BADMINTON, every shuttlecock tells a story of passion, perseverance, and triumph.
                </p>
                <p className="about-text">- Vivek V. Jadhav</p>
            </div>



          </div>
     
         </div>
        
        </div>
       </div>
        </div>
    
       </div>
        </>
    )
}